import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import EvaluateCreditLineButton from './EvaluateCreditLineButton';
import CreditLineLinearProgress from '../progress/CreditLineLinearProgress';

const CreditLineSection = ({
  creditLines,
  currencies,
  masterEntityId,
  createCreditLineEvaluationRequests,
  isLoading,
}) => {
  const creditLinesByCurrency = creditLines.reduce((acc, creditLine) => {
    acc[creditLine.currency?.isoCode] = creditLine;
    return acc;
  }, {});

  const allCreditLines = currencies?.map((currency) => {
    const existingCreditLine = creditLinesByCurrency[currency.isoCode];

    return existingCreditLine || {
      id: `missing-${currency.isoCode}`,
      creditUsed: 0,
      limitAmount: 0,
      hasPendingEvaluation: false,
      currency,
    };
  });

  return (
    <Stack spacing={2}>
      {allCreditLines?.map((creditLine) => (
        <Stack key={creditLine.id} direction="row" alignItems="center" spacing={2}>
          <EvaluateCreditLineButton
            disabled={isLoading || creditLine.hasPendingEvaluation}
            onClick={() => createCreditLineEvaluationRequests({
              variables: {
                masterEntity: masterEntityId,
                currency: creditLine.currency.isoCode,
              },
            })}
            isEvaluating={isLoading || creditLine.hasPendingEvaluation}
          />
          <CreditLineLinearProgress creditLine={creditLine} />
        </Stack>
      ))}
    </Stack>
  );
};

CreditLineSection.propTypes = {
  creditLines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      creditUsed: PropTypes.number,
      limitAmount: PropTypes.number,
      hasPendingEvaluation: PropTypes.bool,
      currency: PropTypes.shape({
        isoCode: PropTypes.string.isRequired,
      }),
    }),
  ),
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      isoCode: PropTypes.string.isRequired,
    }),
  ).isRequired,
  masterEntityId: PropTypes.string.isRequired,
  createCreditLineEvaluationRequests: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

CreditLineSection.defaultProps = {
  creditLines: [],
  isLoading: false,
};

export default CreditLineSection;
