import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

const EvaluateCreditLineButton = ({ disabled, onClick, isEvaluating }) => (
  <Button
    color="primary"
    size="small"
    variant="outlined"
    sx={{
      padding: '4px 8px',
      width: '100px',
      justifyContent: 'center',
    }}
    disabled={disabled}
    onClick={onClick}
  >
    {isEvaluating ? 'Evaluando' : 'Evaluar línea'}
  </Button>
);

EvaluateCreditLineButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isEvaluating: PropTypes.bool,
};

EvaluateCreditLineButton.defaultProps = {
  disabled: false,
  isEvaluating: false,
};

export default EvaluateCreditLineButton;
