import React from 'react';
import PropTypes from 'prop-types';
import RoundedWhiteBox from '../boxes/RoundedWhiteBox';
import { FingoDataGrid } from '../dataGrids';

const CollectionSummaryDataGrid = ({ collectionManagersByPriority, columns }) => (
  <RoundedWhiteBox>
    <FingoDataGrid
      rows={collectionManagersByPriority}
      columns={columns}
      getRowId={(row) => row.collectionPriority}
      rowHeight={30}
      serverFilters={false}
      hideFooter
    />
  </RoundedWhiteBox>
);

CollectionSummaryDataGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  collectionManagersByPriority: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
};

export default CollectionSummaryDataGrid;
