import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LazyImage from '../images/LazyImage';

const CurrencyFlag = ({ isoCode }) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <LazyImage
      imagePath={`images/flags/${isoCode.toLowerCase()}-flag.png`}
      alt={`${isoCode} flag`}
      width={24}
      height={24}
    />
    <Typography component="span">{isoCode}</Typography>
  </Stack>
);

CurrencyFlag.propTypes = {
  isoCode: PropTypes.string.isRequired,
};

export default CurrencyFlag;
